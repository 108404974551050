





















import {
  defineComponent,
  PropType,
  ref,
  watch,
  computed,
} from '@vue/composition-api'

import HLine from '@/components/atoms/HLine.vue'
import DateGroup from '@/components/organisms/h/item/date/DateGroup.vue'
import ItemHierarchyGroup from '@/components/organisms/h/item/hierarchy/ItemHierarchyGroup.vue'
import ExtractConditionLayout from '@/components/organisms/h/layout/ExtractConditionLayout.vue'
import {
  dateRange,
  dateGroupExtractCondition,
  adjustedDowIndexExtractCondition,
  itemHierarchyGroupExtractCondition,
  itemHierarchyUseList,
} from '@/types/entities'

export default defineComponent({
  components: {
    ExtractConditionLayout,
    ItemHierarchyGroup,
    DateGroup,
    HLine,
  },
  props: {
    defaultDateValue: {
      type: Array as PropType<string[]>,
      default: () => ['', ''],
    },
  },
  setup(props, context) {
    const itemStoreUseList = ref<itemHierarchyUseList>([['Chubunrui']])
    const extractConditionOfDate = ref<dateGroupExtractCondition>({})
    // 商品の絞り込み
    const extractConditionOfItemStore = ref<itemHierarchyGroupExtractCondition>(
      {}
    )

    // いずれかの抽出条件変更時の処理
    const changeExtractCondition = () => {
      const cond: adjustedDowIndexExtractCondition = {
        validData:
          extractConditionOfDate.value.dateRange ||
          (props.defaultDateValue as dateRange),
        chubunrui: extractConditionOfItemStore.value.chubunrui || [],
        chubunruiCount: extractConditionOfItemStore.value.chubunruiCount || 0,
      }
      // console.log('extract_changeExtractCondition', { cond })
      context.emit('change-extract-condition', cond)
    }

    /*********************************
     * 以下は各抽出条件変更時の処理
     *********************************/
    const changeExtractConditionOfDate = (
      params: dateGroupExtractCondition
    ) => {
      extractConditionOfDate.value = params
      changeExtractCondition()
    }
    const changeExtractConditionOfItemStore = (
      params: itemHierarchyGroupExtractCondition
    ) => {
      extractConditionOfItemStore.value = params
      changeExtractCondition()
    }

    const requiredItemInput = ['有効日：常に入力', '中分類：常に入力']
    return {
      requiredItemInput,
      itemStoreUseList,
      changeExtractConditionOfDate,
      changeExtractConditionOfItemStore,
    }
  },
})
